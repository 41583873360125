
import OnvocadoLogo from "~/static/logo/logo_light.svg?inline";

export default {
  components: {
    OnvocadoLogo,
  },
  computed: {
    year() {
      const now = new Date()
      return now.getFullYear()
    }
  },
  data() {
    return {
      showIllustrationCredits: false,
    };
  },
  created() {
    this.showIllustrationCredits = this.$route.name === 'product___en'
  }
}


export default {
  props: {
    seo: Object,
  },
  head() {
    return {
      title: this.seo.metaTitle,
      meta: [
        {
          hid: 'title',
          property: 'title',
          content: this.seo.metaTitle
        },
        {
          hid: 'description',
          name: 'description',
          content: this.seo.metaDescription
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.seo.metaTitle
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.seo.metaDescription
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.seo.metaImage?.data?.attributes?.url
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.seo.metaDescription
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: this.seo.metaImage?.data?.attributes?.url
        },
        // Static meta
        // {
        //   hid: 'developer',
        //   property: 'developer',
        //   content: 'onvocado digital'
        // },
      ],
      link: [
        // Static meta
        {
          rel: 'developer',
          href: 'https://onvocado.digital/',
        },
        {
          rel: 'support',
          href: 'https://onvocado.com/',
        },
      ]
    }
  },
  mounted() {
    if (this.seo.onvocadoPixelURL) {
     this.insertOnvocadoPixel();
    }
  },
  methods: {
    insertOnvocadoPixel() {
      const onvocadoScriptTag = document.createElement("script");
      onvocadoScriptTag.type = "text/javascript";
      onvocadoScriptTag.id = "onvocadoScript";
      onvocadoScriptTag.charset = "utf-8";
      onvocadoScriptTag.src = (this.seo.onvocadoPixelURL);
      onvocadoScriptTag.defer = true;
      document.head.appendChild(onvocadoScriptTag);
    }
  }
}

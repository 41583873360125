
export default {
  name: 'MailChimpInput',
  props: {
    btnText: {
      type: String,
      required: false,
      default: "Get Early Access"
    },
    outline: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
